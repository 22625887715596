"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var labels = {
  languages: {
    lang_1: 'Norsk'
  }
};
var _default = labels;
exports["default"] = _default;