"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var titles = {
  soldiagramTitle: 'Soldiagram'
};
var _default = titles;
exports["default"] = _default;