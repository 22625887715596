"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault")["default"];
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread2"));
var _defaultAppData = _interopRequireDefault(require("./defaultAppData"));
var defaultPost = {
  '_id': 1,
  'author': {
    '_id': 1,
    'name': {
      'first': 'First',
      'last': 'Last'
    }
  },
  'content': JSON.stringify((0, _objectSpread2["default"])({}, _defaultAppData["default"]))
};
var _default = defaultPost;
exports["default"] = _default;